@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
