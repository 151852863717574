@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans HK';
  src: url('./NotoSansHK-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
