@import './fonts/Quicksand/typography.css';
@import './fonts/NotoSansJP/NotoSansJP.css';
@import './fonts/NotoSansHK/NotoSansHK.css';

html {
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}
